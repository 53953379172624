import {
  PromptList,
  Report,
  ReportTab,
} from "source/components/matrix/types/reports.types";
import { useDispatch } from "react-redux";
import api from "source/api";
import { addReportResponse } from "source/redux/matrix";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { TimeFormat } from "source/utils/common/time";
import {
  PosthogAction,
  PosthogObject,
  useLogPosthog,
} from "source/hooks/tracking/usePosthogTracking";
import { queryClient } from "pages/_app";
import {
  DEFAULT_COLUMN_WIDTHS,
  DEFAULT_ROW_RUN_LIMIT,
  RETRIEVE_COL_MAGIC_VALUE,
} from "source/components/matrix/tables/config";

import { isDev } from "../../constants";
import {
  getDefaultMatrixName,
  getReportMetaFromTemplate,
} from "source/components/matrix/utils";
import { ReportToolDependency } from "source/components/matrix/types/tools.types";
import { reportsKeys } from "source/api/matrix/useQueryReports";
import { getDefaultModel } from "source/utils/matrix/models";

export const useCreateReport = () => {
  const dispatch = useDispatch();
  const { logPosthog } = useLogPosthog();
  const defaultModel = getDefaultModel();

  const generateNewTab = (tabId: string): ReportTab => {
    // The new tab has a summary column and prompt by default.
    // The backend automatically creates a precomputed tool for the column.
    const newTab: ReportTab = {
      tab_id: tabId,
      name: "Tab 1",
      tools: [],
      column_metadata: [],
      prompts: [],
      cells: [],
      grouping_content: [],
    };
    return newTab;
  };

  const createReport = async ({
    orgId,
    replaceReport,
    documentIds,
  }: {
    orgId: string;
    replaceReport: boolean;
    documentIds?: string[];
  }): Promise<Report> => {
    const requestId = uuidv4();
    const now = moment().format(TimeFormat.RFC3339);
    const reportId = uuidv4();
    const tabId = uuidv4();
    const tab: ReportTab = generateNewTab(tabId);
    const newReport: Report = {
      id: reportId,
      request_id: requestId,
      version_id: uuidv4(),
      created_at: now,
      updated_at: now,
      name: getDefaultMatrixName(),
      tabs: [tab],
      active_tab_id: tabId,
    };

    logPosthog(PosthogObject.MATRIX, PosthogAction.CREATED, {
      matrixId: reportId,
    });

    const reportResp = await api.reports.createWithDocs(
      newReport,
      orgId,
      documentIds
    );

    if (replaceReport) {
      dispatch(
        addReportResponse({ tabId, report: reportResp, cellContents: [] })
      );
    }
    return reportResp;
  };

  const createReportFromTemplate = async ({
    template,
    orgId,
    documentIds,
  }: {
    template: PromptList;
    orgId: string;
    documentIds?: string[];
  }): Promise<Report> => {
    const { prompts, columnMetadata, columnToolParams } =
      getReportMetaFromTemplate(defaultModel, template);
    const prefix = isDev ? "[LOCAL_DEV] - " : "";
    const reportName = `${prefix}Copy of ${template.name}`;
    const { answerColWidth } = DEFAULT_COLUMN_WIDTHS;
    const templateId = template.id;

    const requestId = uuidv4();
    const reportId = uuidv4();
    const tabId = uuidv4();
    const now = moment().format(TimeFormat.RFC3339);

    const tools: ReportToolDependency[] = prompts.map(
      ({ x, static_column_id }) => ({
        tool: "find",
        tab_id: tabId,
        request_id: uuidv4(),
        x,
        dependencies: [0],
        enabled: true,
        tool_params: columnToolParams?.[static_column_id],
        coordinates: Array.from(Array(DEFAULT_ROW_RUN_LIMIT).keys()),
        dependency_columns: [RETRIEVE_COL_MAGIC_VALUE],
        static_column_id,
      })
    );

    const gridConfiguration = prompts.map(({ prompt, static_column_id }) => ({
      colId: static_column_id,
      width: answerColWidth,
      hide: false,
    }));

    const newTab: ReportTab = {
      tab_id: tabId,
      name: "Tab 1",
      tools: tools,
      cells: [],
      prompts: prompts,
      column_metadata: columnMetadata,
      grouping_content: [],
      grid_configuration: gridConfiguration,
    };
    const newReport: Report = {
      id: reportId,
      template_id: templateId,
      request_id: requestId,
      version_id: uuidv4(),
      created_at: now,
      updated_at: now,
      name: reportName,
      tabs: [newTab],
      active_tab_id: tabId,
    };

    logPosthog(PosthogObject.MATRIX, PosthogAction.CREATED, {
      matrixId: reportId,
    });
    logPosthog(PosthogObject.MATRIX, PosthogAction.CREATED_FROM_TEMPLATE, {
      templateId: template.id,
      templateName: template.name,
      matrixId: reportId,
    });
    const reportResp = await api.reports.createWithDocs(
      newReport,
      orgId,
      documentIds
    );
    dispatch(
      addReportResponse({
        tabId,
        report: reportResp,
        cellContents: [],
      })
    );
    // Force reports homepage to re-fetch report list
    // this new report will not be empty, so it will appear in the list
    await queryClient.invalidateQueries({ queryKey: reportsKeys.all });
    return reportResp;
  };

  const createGiga = async (reportId: string, orgId: string) => {
    await api.reports.createGigaMatrix(reportId, orgId).then((res) => {
      const url = `${window.location.origin}/matrix?matrix_id=${res.matrix_id}`;
      window.open(url, "_blank")?.focus();
    });
  };

  return {
    createReport,
    createReportFromTemplate,
    createGiga,
  };
};
