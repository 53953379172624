import React, { useEffect } from "react";
import { NextPage } from "next";
import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import { useSelector } from "react-redux";
import { useCreateReport } from "source/hooks/matrix/useCreateReport";
import { getCurrentOrg, getOrgsLoaded } from "source/redux/organization";
import logger from "source/utils/logger";
import { Loading } from "source/components/shared/Loading";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { useGetRouter } from "source/hooks/useGetRouter";
import {
  useQueryPromptLists,
  useQueryPublicPromptLists,
} from "source/api/matrix/useQueryReports";

const NewMatrixPage: NextPage = () => {
  const { router } = useGetRouter();
  const { createReport, createReportFromTemplate } = useCreateReport();
  const orgId = useSelector(getCurrentOrg)?.id;

  const currentOrg = useSelector(getCurrentOrg);
  const { data: publicTemplateData, isLoading: isPublicTemplatesLoading } =
    useQueryPublicPromptLists();
  const { data: orgTemplateData, isLoading: isOrgTemplatesLoading } =
    useQueryPromptLists(currentOrg?.id);
  const { goToHome, goToMatrix } = useGlobalNavigator();
  const orgsLoaded = useSelector(getOrgsLoaded);

  useEffect(() => {
    if (!orgsLoaded || isPublicTemplatesLoading || isOrgTemplatesLoading)
      return;
    if (!orgId) {
      logger.warn("No org_id present, redirecting to homepage");
      goToHome();
    } else if (router.query.matrix_template_id) {
      // see if we have a public or org template for this id
      const template = publicTemplateData?.find(
        (t) => t.id === router.query.matrix_template_id
      );

      if (!template) {
        const orgTemplate = orgTemplateData?.find(
          (t) => t.id === router.query.matrix_template_id
        );
        if (orgTemplate) {
          createReportFromTemplate({ template: orgTemplate, orgId }).then(
            (data) => {
              const matrixId = data.id;
              const tabId = data.tabs[0]?.tab_id;

              logger.info(
                "Created matrix from org template, routing to new matrix.",
                {
                  matrix_id: matrixId,
                }
              );

              goToMatrix(matrixId, tabId, undefined, orgTemplate.id);
            }
          );
        } else {
          logger.warn("No template found for matrix_template_id", {
            matrix_template_id: router.query.matrix_template_id,
          });
          goToHome();
        }
      } else {
        createReportFromTemplate({ template, orgId }).then((data) => {
          const matrixId = data.id;
          const tabId = data.tabs[0]?.tab_id;

          logger.info(
            "Created matrix from public template, routing to new matrix.",
            {
              matrix_id: matrixId,
            }
          );

          goToMatrix(matrixId, tabId, undefined, template.id);
        });
      }
    } else {
      createReport({
        orgId,
        replaceReport: true,
      })
        .then((data) => {
          const matrixId = data.id;
          logger.info("Created matrix, routing to new matrix.", {
            matrix_id: matrixId,
          });
          goToMatrix(matrixId);
        })
        .catch((e) => {
          logger.error("Unable to create Matrix", { error: e });
          goToHome();
        });
    }
  }, [
    orgsLoaded,
    router.query,
    isPublicTemplatesLoading,
    isOrgTemplatesLoading,
    publicTemplateData,
    orgTemplateData,
  ]);

  return <Loading />;
};

// fast/cached SSR page
// use this for public pages so we can cache ssr
export default withPageAuthRequired(NewMatrixPage, { returnTo: "/matrix/new" });
